import { useCallback } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { Enviroments } from "../enviroments/enviroments";

const useUpdateBalance = (userId, callback) => {
  const handleUpdateBalance = useCallback(
    async balance => {
      try {
        await axios.put(`${Enviroments.API_URL_IA}users/${userId}`, {
          firstBalance: parseFloat(balance)
        });
        Swal.fire("Atualizado!", "Seu saldo foi atualizado.", "success");
        callback(balance);
      } catch (error) {
        console.error("Error updating balance:", error);
        Swal.fire("Erro", "Ocorreu um erro ao atualizar o saldo.", "error");
        callback(-1);
      }
    },
    [userId]
  );

  const openUpdateBalanceModal = useCallback(() => {
    Swal.fire({
      title: "Atualizar Saldo",
      input: "number",
      inputLabel: "Insira abaixo o valor atual da sua banca (R$)",
      inputPlaceholder: "Insira o valor atual da banca.",
      showCancelButton: true,
      confirmButtonText: "Atualizar",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "swal2-dark",
        confirmButton: "swal2-dark",
        cancelButton: "swal2-dark"
      },
      preConfirm: balance => {
        if (!balance) {
          Swal.showValidationMessage(`Por favor, insira um saldo válido`);
        }
        return balance;
      }
    }).then(result => {
      if (result.isConfirmed) {
        handleUpdateBalance(result.value);
      }
    });
  }, [handleUpdateBalance]);

  return openUpdateBalanceModal;
};

export default useUpdateBalance;
